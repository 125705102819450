const formatter = new Intl.NumberFormat();

export type Variant = {
  name: string;
  price: number;
  numAvailable: number;
};

export type Work = {
  name: string;
  id: number;
  poa: boolean;
  variants: Variant[];
};

export type ItemInventory = {
  available: number;
  purchased: number;
  poa?: boolean;
  price: number;
};

export type ItemInventoryRow = ItemInventory & {
  variant_name: string;
};

type ItemInventorySet = Record<string, ItemInventory>;

export async function getInventory(work_id: number) {
  const res = await fetch(`https://vg36n7rbsk7wlwrr2enzr75fpe0nwfth.lambda-url.ap-southeast-2.on.aws/inventory/${work_id}`, {
    mode: "cors",
  });
  const inventory = (await res.json()) as ItemInventorySet;
  return Object.entries(inventory).map(([k, v]) => ({ ...v, variant_name: k }));
}

export interface CartLineItem {
  work: Work;
  variant: Variant;
  qty: number;
}

export type CartShipping = { type: "pickup"; cost: 0 } | { type: "ship"; cost: number };

export interface Cart {
  items: CartLineItem[];
  email: string;
  instructions: string;
  shipping: CartShipping;
}

export function getCart(): Cart {
  const sval = window?.sessionStorage.getItem("mc_cart");
  if (!sval) {
    return {
      items: [],
      instructions: "",
      email: "",
      shipping: { type: "pickup", cost: 0 },
    };
  }
  return JSON.parse(sval) as Cart;
}

export function saveCart(cart: Cart) {
  window.sessionStorage.setItem("mc_cart", JSON.stringify(cart));
}

export function addToCart(work: Work, variant: Variant, qty: number) {
  const cart = getCart();
  let li = cart.items.find((item) => item.work.id == work.id && item.variant.name == variant.name);

  if (li) {
    li.qty += qty;
  } else {
    li = { work, variant, qty };
    cart.items.push(li);
  }
  if (li.qty <= 0) {
    const idx = cart.items.indexOf(li);
    cart.items.splice(idx, 1);
  }
  saveCart(cart);
  return cart;
}

export class BuyButton extends HTMLElement {
  work_id: number | null = null;
  variant: string | null = null;
  variants: Variant[] = [];
  name: string | null = null;
  mode: "buy" | "view" = "buy";
  poa: boolean = false;
  visible: boolean = false;
  inventory: ItemInventoryRow[] = [];
  button: HTMLButtonElement | undefined;

  constructor() {
    super();
  }

  purchase = async () => {
    if (!this.hasAttribute("disabled")) {
      if (this.mode == "buy") {
        const variantName = this.variant ?? "default";
        const variant = this.variants.find((v) => v.name == variantName);
        if (variant) {
          const inventory = await getInventory(this.work_id!);
          addToCart({ name: this.name!, variants: this.variants!, poa: false, id: this.work_id! }, variant, 1);
          window.location.href = "/cart";
        } else {
          window.location.href = `/work/${this.work_id}`;
        }
      }
    }
  };

  fetchInventory = async () => {
    if (this.work_id) {
      this.inventory = await getInventory(this.work_id);
      this.update();
    }
  };

  connectedCallback(): void {
    this.variants = JSON.parse(this.getAttribute("variants") ?? "[]");

    this.button = document.createElement("button");
    this.button.className = "btn";
    this.button.addEventListener("click", this.purchase);
    this.appendChild(this.button);

    this.variant = this.getAttribute("variant");
    this.poa = this.hasAttribute("poa");
    this.work_id = parseInt(this.getAttribute("work_id") ?? "0");
    this.name = this.getAttribute("name");

    this.inventory = this.variants.map((v) => ({
      variant_name: v.name,
      available: v.numAvailable,
      poa: this.poa,
      purchased: -1,
      price: v.price,
    }));
    intObvs.add(this, this.fetchInventory);
    this.update();
  }

  disconnectedCallback() {
    intObvs.remove(this);
  }

  update() {
    const availableVariants = this.inventory.filter((i) =>
      this.variant ? i.variant_name == this.variant : i.available > 0 && i.price > 0
    );
    const anyInStock = availableVariants.find((v) => v.price && v.available > v.purchased);

    let minPrice: number | undefined = undefined;
    let maxPrice: number | undefined = undefined;
    for (const v of availableVariants) {
      if (!minPrice || v.price < minPrice) {
        minPrice = v.price;
      }
      if (!maxPrice || v.price > maxPrice) {
        maxPrice = v.price;
      }
    }

    let prefix: string;
    if (availableVariants.length == 1) {
      prefix = availableVariants[0].variant_name !== "default" ? availableVariants[0].variant_name : "";
    } else {
      prefix = minPrice == maxPrice ? "Opt" : "";
    }

    let state: string;
    let disabled: boolean = true;

    if (availableVariants.length == 0) {
      state = "NFS";
    } else if (this.poa) {
      state = "POA";
    } else if (!anyInStock) {
      state = prefix ? `${prefix} - Sold` : "Sold";
      disabled = true;
    } else {
      disabled = false;
      state =
        minPrice == maxPrice
          ? `${prefix} $${formatter.format(minPrice!)}`
          : `$${formatter.format(minPrice!)}-$${formatter.format(maxPrice!)}`;
    }

    if (this.button) {
      if (disabled) {
        this.button.setAttribute("disabled", "true");
      } else {
        this.button.removeAttribute("disabled");
      }
      if (this.button.textContent !== state) {
        this.button.textContent = state;
      }
    }
  }
}

type VisibilityCallback = (visible: boolean) => any;
interface VisEntry {
  el: Element;
  cb: VisibilityCallback;
}

class IntersectionNotifier {
  intObvs: IntersectionObserver;
  entries: VisEntry[] = [];

  constructor() {
    this.intObvs = new IntersectionObserver(this.cb, {
      rootMargin: "200px",
    });
  }

  cb = (entries: IntersectionObserverEntry[]) => {
    for (let entry of entries) {
      const reg = this.entries.find((e) => e.el == entry.target);
      if (reg) {
        reg.cb(entry.isIntersecting);
      }
    }
  };

  add(el: Element, cb: VisibilityCallback) {
    this.intObvs.observe(el);
    this.entries.push({ el, cb });
  }

  remove(el: Element) {
    this.intObvs.unobserve(el);
    let idx = this.entries.findIndex((e) => e.el == el);
    if (idx >= 0) {
      this.entries.splice(idx, 1);
    }
  }
}

const intObvs = new IntersectionNotifier();

customElements.define("mc-buybutton", BuyButton);
